
import { computed, onMounted, watch, defineComponent, ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import ApiService from "@/core/services/ApiService";
import EntityTable from "@/components/EntityTable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CheckModal from "@/components/modals/CheckModal.vue";
import { Modal } from "bootstrap";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import router from "@/router";
import { useStore } from "vuex";
import TableExplorer from "@/components/TableExplorer.vue";
import NewClaimModal from "@/components/modals/NewClaim.vue";
import SuspendDonationModal from "@/components/modals/SuspendDonation.vue";

type Location = {
  lat: number | null;
  lng: number | null;
};

export default defineComponent({
  name: "new-donor-form",
  props: {
    endpoint: String,
    title: {
      type: String,
      default: "Formulario de Donante",
    },
    showRequired: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Array,
      default: () => {
        return ["type"];
      },
    },
  },
  components: {
    SuspendDonationModal,
    NewClaimModal,
    TableExplorer,
    ElectronicSignature,
    CheckModal,
    EntityTable,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      key: process.env.VUE_APP_MAPS_API_KEY,
      filters: {
        donor_id: "",
      },
      visitColumns: [
        {
          name: "date",
          label: "Fecha",
        },
        {
          name: "collector",
          label: "Recolector",
        },
        {
          name: "status",
          label: "Estado",
        },
      ],
      claimColumns: [
        {
          name: "date",
          label: "Fecha",
        },
        {
          name: "status",
          label: "Estado",
        },
        {
          name: "type",
          label: "Motivo/s",
        },
        {
          name: "extra",
          label: "Observaciones",
        },
        {
          name: "actions",
          label: "Acciones",
        },
      ],
      checkColumns: [
        {
          name: "created_at",
          label: "Fecha",
        },
        {
          name: "user",
          label: "Usuario",
        },
        {
          name: "result",
          label: "Resultado",
        },
        {
          name: "additional_info",
          label: "Observaciones",
        },
      ],
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      geolocateLoading: false,
      loading: false,
      visitorList: [],
      localityList: [],
      stateList: [],
      scheduleList: ["Todos los días"],
      transportList: [],
      criticalError: null,
      zoomLevel: 16,
      errors: {},
      entity: {
        schedule_id: -1,
      },
    };
  },
  setup() {
    const donorCheckModal = ref<null | HTMLElement>(null);
    const suspendDonationModal = ref<null | HTMLElement>(null);
    const checkEntityTable = ref();

    const store = useStore();

    const mapRef = ref(null);
    const markerRef = ref(null);
    const location = ref<Location | null>({ lat: -34.603722, lng: -58.381592});
    const entityLoc = computed({
      get: () => location,
      set: (value) => {
        location.value = value.value;
      },
    });

    return {
      store,
      entityLoc,
      donorCheckModal,
      suspendDonationModal,
      checkEntityTable,
    };
  },
  computed: {
    getScheduleName() {
      return "Todos los días";
    },
  },
  methods: {
    setTag(tag) {
      Swal.fire({
        title: "¿Teléfono Inválido?",
        width: 500,
        icon: "question",
        confirmButtonText: "Sí",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: "No",
        cancelButtonText: "Cancelar",
      })
        .then((result) => {
          if (result.isConfirmed) {
            ApiService.post("/donors/" + (this.entity as any).id + "/tags", {
              tag: tag,
            }).then(() => {
              let myModal = document.getElementById("check-modal");
              this.requestData();
              if (myModal) {
                myModal.click();
              }
            });
          } else if (result.isDenied) {
            ApiService.delete("/donors/" + (this.entity as any).id + "/tags/"+tag).then(() => {
              let myModal = document.getElementById("check-modal");
              this.requestData();
              if (myModal) {
                myModal.click();
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    suspendDonation() {
      this.performControlAnd("Suspender donación", () => {
        let modal = new Modal(document.getElementById("SuspendDonation-modal"));
        modal.show();
      });
    },
    newClaim() {
      this.performControlAnd("Registrar reclamo", () => {
        let modal = new Modal(document.getElementById("NewClaim-modal"));
        modal.show();
      });
    },
    resumeDonor() {
      this.performControlAnd("Continuar donación", () => {
        let modal = new Modal(document.getElementById("resumeSignature"));
        modal.show();
      });
    },
    resumeSubmit(signature) {
      ApiService.delete("donors/" + this.filters.donor_id + "/suspension")
        .then(() => {
          this.requestData();
        })
        .catch(this.catchErrors);
    },
    checkCancelSubmit(signature) {
      ApiService.post("checks", {
        donorId: this.filters.donor_id,
        result: "cancel_others",
        electronicSignature: signature,
      }).then(() => {
        let myModal = document.getElementById("check-modal");
        if (myModal) {
          myModal.click();
        }
        this.requestData();
      });
    },
    reactivate() {
      this.performControlAnd("Reactivación de donante", () => {
        this.requestData();
        router.push({
          name: "donor-reactivate",
          params: { id: this.filters.donor_id },
        });
      });
    },
    performControl() {
      this.performControlAnd(
        "Control de Activa",
        () => {
          this.requestData();
          router.push({
            name: "donor-edit",
            params: { id: this.filters.donor_id },
          });
        },
        () => {
          this.terminateForm();
        }
      );
    },
    performControlAnd(title, callback, failed?) {
      Swal.fire({
        title: title,
        width: 500,
        text: "¿La donante respondió satisfactoriamente las preguntas del cuestionario?",
        icon: "question",
        confirmButtonText: "Sí",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: "No",
        cancelButtonText: "Cancelar",
      })
        .then((result) => {
          if (result.isConfirmed) {
            ApiService.post("checks", {
              donorId: this.filters.donor_id,
              result: "ok",
              extra: "",
            }).then(() => {
              let myModal = document.getElementById("check-modal");
              if (myModal) {
                myModal.click();
              }
              callback();
            });
          } else if (result.isDenied) {
            if (failed) {
              failed();
            } else {
              this.terminateForm();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    terminateForm() {
      var modalito = new Modal(document.getElementById("check-modal"));
      modalito.show();
    },

    reassignSchedule() {
      Swal.fire({
        title: "Cambio de día de recolección",
        text: "Seleccione el nuevo cronograma de recolección para esta donante.",
        input: "select",
        inputValue: this.entity.schedule_id,
        customClass: {
          input: "form-control form-control-solid",
        },
        confirmButtonText: "Reasignar",
        cancelButtonText: "Cancelar",
        inputOptions: this.scheduleList,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debe elegir un cronograma";
          }
        },
        preConfirm: (value) => {
          ApiService.post("donors/" + this.filters.donor_id + "/schedule", {
            scheduleId: value,
          })
            .then((response) => {
              this.requestData();
            })
            .catch(this.catchErrors);
        },
      });
      return false;
    },
    catchErrors(error) {
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    getStatusText(status) {
      if (status == "inactive") {
        return "Inactiva";
      } else if (status == "suspended") {
        return "Suspendida";
      } else {
        return "Activa";
      }
    },
    getBadgeText(status) {
      return status;
    },
    getBadgeClass(status) {
      if (status === "ok") {
        return "badge-success";
      }
      return "badge-danger";
    },
    getVisitStatusText(status) {
      switch (status) {
        case "pending":
          return "Pendiente";
        case "incident":
          return "Incidencia";
        case "collected":
          return "Recolectado";
        default:
          return "Desconocido";
      }
    },
    getVisitStatusIcon(status) {
      switch (status) {
        case "pending":
          return "la-hourglass";
        case "incident":
          return "la-exclamation-circle text-danger";
        case "collected":
          return "la-check-circle text-success";
        default:
          return "la-question-circle";
      }
    },
    getVisitStatusClass(status) {
      switch (status) {
        case "pending":
          return "badge badge-secondary";
        case "incident":
          return "badge badge-light-danger";
        case "collected":
          return "badge badge-light-success";
        default:
          return "la-question-circle";
      }
    },
    getStatusClass(status) {
      if (status == "inactive") {
        return "danger";
      } else if (status == "suspended") {
        return "warning";
      } else {
        return "success";
      }
    },
    requestData() {
      this.loading = true;

      if (this.checkEntityTable) {
        this.checkEntityTable.retrieveData();
      }

      ApiService.get("donors", this.filters.donor_id).then((response) => {
        response.data = response.data.data;
        if (response.data.location) {
          this.entityLoc.value = {
            lat: response.data.location.coordinates[1],
            lng: response.data.location.coordinates[0],
          };
        }
        this.entity = response.data;

        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.filters.donor_id = this.$route.params.id.toString();
    }

    this.requestData();
  },
});
